var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "page"
        }, [_c('img', {
          attrs: {
            "src": require("./images/success.png")
          }
        }), _c('div', {
          staticClass: "tip"
        }, [_vm._v("领用成功")]), _c('div', {
          staticClass: "backBtn"
        }, [_c('van-button', {
          attrs: {
            "type": "primary",
            "round": "",
            "block": ""
          },
          on: {
            "click": function ($event) {
              return _vm.goBack();
            }
          }
        }, [_vm._v("返回")])], 1)])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };